import React, { useState } from 'react';
import AdminLayout from '../../layouts/admin';
import Api from '../../api';

import gql from 'graphql-tag';

import { Form, Button, Input } from 'antd';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const createUserQuery = gql`
	mutation UserCreateInitial($key: String!) {
		UserCreateInitial(key: $key) {
			email
		}
	}
`;

const UserInitPage = ({ location }) => {
	const createUser = async (values) => {
		const result = await Api.mutate(createUserQuery, { key: values.key });
		if (!result.errors) {
			alert('Created initial user succesfully');
		} else {
			alert('An error occured when creating the user');
		}
	};

	return (
		<AdminLayout path={location.pathname} breadcrumbs={[{ link: '/admin/users', title: 'Init user'}]}>
			<h1>
				Init users
			</h1>

			<Form
				{...formItemLayout}
				initialValues={{
					key: '',
				}}
				onFinish={createUser}
			>
				<Form.Item label="Key" name="key" rules={[{ required: true, message: 'key is verplicht' } ]}>
					<Input />
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button htmlType="submit" type="primary">
						Opslaan
					</Button>
				</Form.Item>
			</Form>
	
		</AdminLayout>
	);
};

export default UserInitPage;
